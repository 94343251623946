<!--
 * @author: JJ
 * @since: 2024-07-19
 * ContactUsView.vue
-->
<template>
    <div class="container bg-white">
        <Header />
        <div class="swiper_link">
            <div class="swiper_sw">
                <div class="swiper_item">
                    <div class="top_banner cu flex flex-center flex-col" :style="{ backgroundImage: `url(${banner})` }">
                        <div class="top_banner_info tl">
                            <div class="colf fs50 lh60">{{ $t('about.title7') }}</div>
                            <div class="fs24 lh44 colf mt30" v-if="language=='zh'"><span>{{ span1 }}</span></div>
                            <div class="fs24 lh44 colf mt30" v-else><span>{{ span1_en }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w1760 pt70 pb120">
            <div class="title">{{ $t('product.title1') }}</div>
            <div class="pt60">
                <div class="fs30 lh40 fwb col5a">{{language=='zh'?'上海精泰技术有限公司':'Shanghai CHINT Precision Technology Co.,Ltd'}}</div>
                <div class="mt15 fs20 col5a"><span class="fwb">{{ $t('contact.tips')
                        }}{{language=='zh'?'：':':'}}</span><span>400-8177-777{{language=='zh'? '（销售、技术咨询、售后服务）':' (Sales, technical support, after service)'}}</span>
                </div>
                <div class="mt15 fs20 col5a"><span class="fwb">{{ $t('footer.address')
                        }}{{language=='zh'?'：':':'}}</span><span v-if="language=='zh'">海市松江区思贤路3857号1栋4层</span><span v-else>4F, No.1 Building, 3857 Sixian Road, Songjiang District, Shanghai</span></div>
                <div class="mt15 fs20 col5a"><span class="fwb">{{ $t('footer.phone') }}{{language=='zh'?'：':':'}}</span><span>021-37791111</span>
                </div>
            </div>
        </div>
        <div class="pt60 pb40 bg-fa">
            <div class="w1760 ">
                <div class="title">{{ $t('contact.title2') }}</div>
                <img src="../assets/icon/map.png" class="map" alt="">
            </div>
        </div>
        <div class="branch_link w1760">
            <div class="title">{{ $t('contact.title3') }}</div>
            <div class="mt45 flex-box">
                <div class="select_nav">
                    <el-select v-model="selectedValue" placeholder="请选择范围" @change="changeSelect1"
                        popper-class="mySelectStyle">
                        <template v-for="(item, index) in category" :key="index">
                            <el-option class="fs20" :label="language == 'zh' ? item.categoryName : item.categoryNameEn"
                                :value="index"></el-option>
                        </template>

                    </el-select>
                </div>
                <div class="select_nav ml120">
                    <el-select v-model="selectedValueProvince" class="fs20" placeholder="请选择省份"
                        popper-class="mySelectStyle" @change="changeSelect2">
                        <template v-for="(item, index) in province" :key="'province' + index">
                            <el-option class="fs20" :label="language == 'zh' ? item.categoryName : item.categoryNameEn"
                                :value="index"></el-option>
                        </template>
                    </el-select>
                </div>
            </div>
            <div>
                <div class="item flex-col flex-center" v-for="(item, index) in list" :key="index">
                    <div class="fs30 fwb lh40 col5a">{{ language == 'zh' ? item.branchName : item.branchNameEn }}</div>
                    <div class="col5a fs20 lh40"><span class="fwb">{{ $t('footer.phone') }}：</span>{{ language ==
                        'zh' ? item.branchPhone : item.branchPhoneEn}}</div>
                    <div class="col5a fs20 lh40 "><span class="fwb">{{ $t('footer.address') }}：</span>{{ language ==
                        'zh' ? item.branchAddress : item.branchAddressEn}}</div>
                    <div class="col5a fs20 lh40 "><span class="fwb">{{ $t('footer.email') }}：</span>{{ language ==
                        'zh' ? item.branchEmail : item.branchEmailEn}}</div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue"
import { useHead } from '@unhead/vue'
const banner = ref(require('../assets/icon/contus.jpg'));
let language = ref(localStorage.getItem('language') || 'zh');
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
let span1 = ref('正有所为，泰有未来\n建立多样化的人才引进渠道，网罗全球范围内的杰出人才');
let span1_en = ref('Taking action, Tai has a future\nEstablish diverse channels for talent introduction and attract outstanding talents from around the world');
let category = ref([])
let province = ref([])
let list = ref([])
const { proxy } = getCurrentInstance();
let selectedValue = ref('')
let selectedValueProvince = ref('')
let id = ref('')
const changeSelect1 = (e) => {
    console.log(selectedValue);

    province.value = []
    province.value = category.value[e].childrenList

}
const changeSelect2 = (e) => {
    console.log(e);
    id.value = province.value[e].categoryId
    // let i=id.value
    proxy?.$api.ApiBranchList({ 'categoryId': id.value }).then((res) => {
        if (res.code == 200) {
            list.value = res.data
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
onMounted(() => {


    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });

    // 获取分类
    proxy?.$api.ApiBranchCategory({}).then((res) => {
        category.value = res.data
        console.log(category.value);
        selectedValue.value = 0
        province.value = []
        province.value = category.value[0].childrenList
        id.value = province.value[0].categoryId
        console.log(id.value);
        
        selectedValueProvince.value=0
        proxy?.$api.ApiBranchList({ 'categoryId': id.value }).then((res) => {
            if (res.code == 200) {
                list.value = res.data
            } else {
                proxy?.$message.error(res.msg);
            }
        })
    })
});

</script>
<style lang="less" scoped>
.swiper_link {
    width: 100%;
    height: calc(100vw / 1920 * 550);
    position: relative;

    .swiper_sw {
        width: 100%;
        height: calc(100vw / 1920 * 550);
    }

    .swiper_item {
        width: 100%;
        height: 100%;
    }

    .top_banner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        position: relative;
    }

    .top_banner_info {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
    }

    .top_btn {
        margin-top: 140px;
        width: 140px;
        height: 54px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }
}

.bg-fa {
    background: #fafafa;
}

.map {
    width: 1240px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 45px;
}

.branch_link {
    min-height: 700px;
    padding-top: 70px;
    padding-bottom: 70px;
}

.select_nav {
    width: 400px;
    font-size: 20px;
}

.icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: rgb(86, 215, 255);
}

.ml120 {
    margin-left: 120px;
}

.item {
    margin-top: 55px;
    display: inline-flex;
    vertical-align: top;
    margin-right: 130px;
    border-radius: 10px;
    background: #f6f6f6;
    padding-left: 35px;
    padding-right: 35px;
    width: 500px;
    height: 230px;

    &:nth-of-type(3n) {
        margin-right: 0;
    }
}
</style>
<style lang="less">
/* 覆盖选择器小图标颜色 */
.el-select__caret {
    color: #1e50e6 !important;
    /* 将颜色改为红色 */
}

.el-select-dropdown__item {
    font-size: 20px !important;
}

.el-select__wrapper {
    height: 40px !important;
    font-size: 20px !important;
}
</style>
