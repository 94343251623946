<template>
  <div class="container bg-darkGray">
    <Header />
    <div class="swiper_link">
      <!-- <div class="swiper_sw">
        <div class="swiper_item">
          <div class="top_banner cu flex flex-center flex-col" :style="{ backgroundImage: `url(${banner})` }">
            <div class="top_banner_info tl">
              <div class="colf fs50 lh60">{{ banner_title }}</div>
              <div class="fs24 lh28 colf mt30">{{ banner_subtitle }}</div>
              <router-link to="/ProductView" class="top_btn">{{ $t('indexHtml.banner1.btn_text') }}</router-link>
            </div>
          </div>
        </div>
      </div> -->
      <swiper :pagination="{ clickable: true }" :autoplay="{ delay: 3000, disableOnInteraction: false }"
        class="swiper_sw" :observer="true" :observeParents="true" :modules="modules" loop>
        <swiper-slide class="swiper_item" v-for="(item, index) in banner" :key="index">
          <a :href="language=='zh'?item.url:item.urlEn" class="top_banner cu flex flex-center flex-col" :style="{ backgroundImage: `url(${item.banner})` }">
            <div class="top_banner_info tl">
              <div class="colf fs50 lh60">{{ language=='zh'?item.title:item.titleEn }}</div>
              <div class="fs24 lh28 colf mt30">{{ language=='zh'?item.subTitle:item.subTitleEn }}</div>
              <a :href="language=='zh'?item.url:item.urlEn" class="top_btn">{{ $t('indexHtml.banner1.btn_text') }}</a>
            </div>
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <div class="bg-normal1">
      <div class="w1760 pt65 pb120">
        <div class="title">{{ $t('headerTabs.tab1') }}</div>
        <div class="mt30 fs24 lh36 col5a">{{ $t('product.subtitle') }}</div>
        <div class="swiper_link1">
          <swiper :pagination="{ clickable: true }" :autoplay="{ delay: 2500, disableOnInteraction: false }" @swiper="onSwiper" @slideChange="onSlideChange" class="swiper_mid"
            :observer="true" :observeParents="true" :modules="modules" loop>
            <swiper-slide class="swiper_item" v-for="(item, index) in productList" :key="index">
              <router-link :to="'/ProductInfoView?id=' + item.articleId" class="swiper_item flex-between flex-box mb30">
                <img :src="item.cover" class="swiper_goods_img" alt="">
                <div class="item_box">
                  <div class="swiper_title">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                  <template v-if="language == 'zh'">
                    <div class=" mt50 fs15 lh25 col5a m-ellipsis-l4" v-if="item?.simpleText">
                      <span v-html="item.simpleText"></span>
                    </div>
                    <div class=" mt50 fs20 lh25 fwb col5a">{{ $t('product.sub') }}</div>
                    <template v-if="item?.mainText">
                      <template v-for="(item, index) in item.mainText" :key="index">
                        <div class="item_text" v-if="index < 4">
                          {{ item }}</div>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <div class=" mt50 fs15 lh25 col5a m-ellipsis-l4" v-if="item?.simpleTextEn">
                      <span v-html="item.simpleTextEn"></span>
                    </div>
                    <div class=" mt50 fs15 lh25 fwb col5a">{{ $t('product.sub') }}</div>
                    <template v-if="item?.mainTextEn">
                      <template v-for="(item, index) in item.mainTextEn" :key="index">
                        <div class="item_text" v-if="index < 4">
                          {{ item }}</div>

                      </template>
                    </template>
                  </template>

                </div>
              </router-link>
            </swiper-slide>
          </swiper>
          <img src="../assets/icon/swiper_l.png" class="icon_job job_l" @click="prev" alt="">
          <!-- <img src="../assets/icon/swiper_l_u.png" v-else class="icon_job job_l" @click="prev" alt=""> -->
          <img src="../assets/icon/swiper_r.png" class="icon_job job_r" @click="next" alt="">
          <!-- <img src="../assets/icon/swiper_r_u.png" v-else class="icon_job job_r" @click="next" alt=""> -->
        </div>
      </div>
    </div>
    <div class="bg-246 pt65 pb50" v-if="swiperBanner?.length > 0">
      <div class=" w1760 ">
        <div class="title">{{ $t('headerTabs.tab2') }}</div>
        <div class="mt30 fs24 lh36 col5a">{{ $t('indexHtml.subtitle2') }}</div>
      </div>
      <swiper :slides-per-view="1.42" :centeredSlides="true" :centeredSlidesBounds="true" @swiper="onSwiper1"
        @slideChange="slideChange" class="swiper_result" :observer="true" :observeParents="true" :modules="modules"
        loop>
        <swiper-slide class="swiper_result_item" v-for="(item, index) in swiperBanner" :key="index">
          <router-link :to="'/ResultInfoView?id=' + item.articleId" class="result_item"
            :style="{ backgroundImage: `url(${item.cover})` }">
            <img src="../assets/icon/moudle.png" v-if="midActive == index" class=" moudle" alt="">
<div class="pr z2"> {{ language == 'zh' ? item.title : item.titleEn }}</div>
           
          </router-link>
        </swiper-slide>
      </swiper>
      <div class="diy_pagination_nav">
        <div class="sq"></div>
        <div class="flex-box flex-center">
          <div class="sq_item cp" v-for="(item, index) in swiperBanner" :key="index" @click="changeMid(index)"
            :class="[midActive == index ? 'active' : '']">{{ language == 'zh' ? item.title : item.titleEn }}</div>
        </div>
      </div>
    </div>
    <div class="bg-white pt65 pb45">
      <div class=" w1760 ">
        <div class="title">{{ $t('headerTabs.tab3') }}</div>
        <div class="mt30 fs24 lh36 col5a">{{ $t('indexHtml.subtitle3') }}</div>
      </div>
      <div class="swiper_link2">
        <swiper @swiper="onSwiper2" @slideChange="onSlideChange2" class="swiper_link2_swiper" :slides-per-view="1.25"
          :modules="modules">
          <swiper-slide v-for="(item, index) in example" :key="index">
            <div class="swiper_item flex-between flex" :class="secondActive == index ? 'active' : ''">
              <template v-for="(v, indexs) in item" :key="indexs">
                <router-link :to="'/ExampleInfoView?id=' + v.articleId" class="first_item" v-if="indexs == 0">
                  <img :src="v.cover" class="first_item_img" alt="">
                  <div class="mt25 m-ellipsis fs24 lh30 fwb col0">{{ language == 'zh' ? v.title : v.titleEn }}</div>
                  <div class="mt25 fs16 lh30 col5a m-ellipsis-l2" v-html="language == 'zh' ? v.content : v.contentEn">
                  </div>
                  <router-link :to="'/ExampleInfoView?id=' + v.articleId" class="bt1">
                    {{ $t('indexHtml.btn') }}
                  </router-link>
                </router-link>
              </template>
              <div>
                <template v-for="(v, indexs) in item" :key="indexs">
                  <template v-if="indexs != 0">
                    <router-link :to="'/ExampleInfoView?id=' + v.articleId" class="second_item">
                      <img src="../assets/icon/banner4.png" class="second_item_img" alt="">
                      <div class="fs24 fwb mt15 m-ellipsis col0">{{ language == 'zh' ? v.title : v.titleEn }}</div>
                      <div class="mt15 fs16 col5a lh30 m-ellipsis-l2"
                        v-html="language == 'zh' ? v.content : v.contentEn"></div>
                    </router-link>
                  </template>
                </template>

              </div>
            </div>
          </swiper-slide>
        </swiper>
        <img src="../assets/icon/swiper_l.png" v-if="secondActive > 0" class="icon_job job_l" @click="prev1" alt="">
        <img src="../assets/icon/swiper_l_u.png" v-else class="icon_job job_l" @click="prev1" alt="">
        <img src="../assets/icon/swiper_r.png" v-if="secondActive != 2" class="icon_job job_r" @click="next1" alt="">
        <img src="../assets/icon/swiper_r_u.png" v-else class="icon_job job_r" @click="next1" alt="">
      </div>
    </div>
    <div class="bg-246 pt65 pb40">
      <div class="w1760">
        <div class="flex flex-align-end pr">
          <div class="title">{{ $t('newsList.title') }}</div>
          <div class="tab_nav flex-grow-1">
            <!-- <div class="tab_item" @click="chooseNewsTab(index)" :class="[nindex == index ? 'active' : '']"
              v-for="(item, index) in tab">{{ item }}</div> -->
          </div>
          <router-link to="/NewsView" class="news_btn">{{ $t('indexHtml.banner1.btn_text') }}</router-link>
        </div>
        <div class="flex flex-between mt50">
          <template v-for="(item, index) in newsList" :key="index">
            <template v-if="index == 0 && item.isNew == 1">
              <a :href="item?.thirdPartyPlatformUrl" v-if="item?.thirdPartyPlatformUrl"
                class="news_item_big flex flex-end flex-col pr" :style="{ backgroundImage: `url(${item.cover})` }">
                <img src="../assets/icon/moudle.png" v-if="midActive == index" class=" moudle" alt="">
                <div class="p3_5 news_item_big flex flex-end flex-col pr z2">
                <div class="fs24 colf lh28">{{ item.updateTime }}</div>
                <div>
                  <div class="news_btn_white">{{ $t('indexHtml.tab_text1') }}</div>

                </div>
                <div class="fs24 colf fwb mt15 m-ellipsis">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                <div class="fs15 colf mt15 m-ellipsis-l2">{{ language == 'zh' ? item.articleAbstract :
                  item.articleAbstractEn
                  }}</div>
                </div>
              </a>
              <router-link v-else :to="'/NewsInfoView?id=' + item.articleId"
                class="news_item_big pr flex flex-end flex-col" :style="{ backgroundImage: `url(${item.cover})` }">
                <img src="../assets/icon/moudle.png" v-if="midActive == index" class=" moudle" alt="">
                <div class="news_item_big p3_5 pr flex flex-end flex-col z2">
                  <div class="fs24 colf lh28">{{ item.updateTime }}</div>
                  <div>
                    <div class="news_btn_white">{{ $t('indexHtml.tab_text1') }}</div>
                  </div>
                  <div class="fs24 colf fwb mt15 m-ellipsis">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                  <div class="fs15 colf mt15 m-ellipsis-l2">{{ language == 'zh' ? item.articleAbstract :item.articleAbstractEn}}</div>
                </div>
              </router-link>

            </template>
          </template>
          <div>
            <template v-for="(item, index) in newsList" :key="index">
              <template v-if="item.isNew == 1 && index != 0">
                <a :href="item?.thirdPartyPlatformUrl" v-if="item?.thirdPartyPlatformUrl" class="news_item flex-box">
                  <img :src="item.cover" class="cover" alt="">
                  <div class="pl30 flex-grow-1">
                    <div class="fs22 lh34 fwb tt m-ellipsis">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                    <div class="fs15 lh24 col5a m-ellipsis mt10">{{ language == 'zh' ? item.articleAbstract :item.articleAbstractEn}}
                    </div>
                    <div class="mt20 flex-box">
                      <div class="news_btn_mini">{{ $t('indexHtml.tab_text1') }}</div>
                      <div class=" fs15 col5a ml15">{{ item.updateTime }}</div>
                    </div>
                  </div>
                </a>
                <router-link v-else :to="'/NewsInfoView?id=' + item.articleId" class="news_item flex-box">
                  <img :src="item.cover" class="cover" alt="">
                  <div class="pl30 flex-grow-1">
                    <div class="fs22 lh34 fwb tt m-ellipsis">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                    <div class="fs15 lh24 col5a m-ellipsis mt10">{{ language == 'zh' ? item.articleAbstract :item.articleAbstractEn}}
                    </div>
                    <div class="mt20 flex-box">
                      <div class="news_btn_mini">{{ $t('indexHtml.tab_text1') }}</div>
                      <div class=" fs15 col5a ml15">{{ item.updateTime }}</div>
                    </div>
                  </div>
                </router-link>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <router-link to="/StudyView" class="router_link flex-box flex-center flex-col" :style="{ backgroundImage: `url(${link_banner1})` }">
      <div class=" fs50 fwb col0">{{ $t('headerTabs.tab5') }}</div>
      <div class="fs24 lh36 tc col5a mt40"><span v-html="$t('indexHtml.subtitle5')"></span></div>
      <router-link to="/StudyView" class="mt120 study_btn">{{ $t('indexHtml.banner1.btn_text') }}</router-link>
    </router-link>
    <a class="router_link flex-box flex-center flex-col" :style="{ backgroundImage: `url(${link_banner2})` }" href="https://social.chint.com/social-recruitment/chint/40744/#/jobs?page=1&anchorName=jobsList&department%5B0%5D=733294">
      <div class=" fs50 fwb col0">{{ $t('about.title4') }}</div>
      <div class="fs24 lh36 tc col5a mt40"><span v-html="$t('indexHtml.subtitle6')"></span></div>
      <a class="mt120 study_btn" href="https://social.chint.com/social-recruitment/chint/40744/#/jobs?page=1&anchorName=jobsList&department%5B0%5D=733294">{{ $t('indexHtml.banner1.btn_text') }}</a>
    </a>
    <need-help></need-help>
    <Footer></Footer>
  </div>
</template>
<script>

import { ref, onMounted, getCurrentInstance } from 'vue'

import { useHead } from '@unhead/vue'
// @ is an alias to /src
import needHelp from "../components/needHelp";
import { Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax } from "swiper";
import { Swiper, SwiperSlide } from "../../node_modules/swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import moment from 'moment';
export default {
  name: 'HomeView',
  components: {
    needHelp,
    Swiper,
    SwiperSlide,
  },
  setup() {
    let dataTitle = ref('')
    let dataContent = ref('')
    let keywords = ref('')
    let serviceHotline = ref('')
    let publicAccountQrcode = ref('')
    let language = ref(localStorage.getItem('language') || 'zh');
    const t = getCurrentInstance().appContext.config.globalProperties;
    let tab = ref([t.$t('indexHtml.tab_text1'), t.$t('indexHtml.tab_text2'), t.$t('indexHtml.tab_text3'), t.$t('indexHtml.tab_text4')]);
    onMounted(() => {
      const { proxy } = getCurrentInstance();
      console.log(language);
      // 使用全局方法
      proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        console.log(res);
        if (data_res.code == 200) {
          if (language.value == 'zh') {
            dataTitle = res.siteName
            dataContent = res.description
            keywords = res.keywords
          } else {
            dataTitle = res.siteNameEn
            dataContent = res.descriptionEn
            keywords = res.keywordsEn
          }
          useHead({
            title: dataTitle,
            meta: [
              { name: 'description', content: dataContent },
              { property: 'og:title', content: dataTitle },
              { property: 'og:description', content: dataContent },
              { name: "keywords", content: keywords }
            ],
          })
        } else {
          proxy?.$message.error(data_res.msg);
        }
      });
    });

    const onSwiper = (e) => {
    };


    const swiperOptionMid = (e) => {
    };
    return {
      onSwiper,
      swiperOptionMid,
      modules: [Navigation, Pagination, Scrollbar, Autoplay, EffectFade, Parallax],
      dataTitle,
      dataContent,
      keywords,
      serviceHotline: serviceHotline,
      publicAccountQrcode: publicAccountQrcode,
      tab,
      language
      // midActive
    };
  },
  data() {
    return {
      banner: '',
      banner_title: '',
      banner_subtitle: '',
      swiperBanner: [],
      banner1: require('../assets/icon/banner1.png'),
      banner2: require('../assets/icon/news_banner.png'),
      link_banner1: require('../assets/icon/study_bg.png'),
      link_banner2: require('../assets/icon/join_bg.png'),
      study_html: '为你提供产品技术、应用、维护相关的\n学习视频和文档，快速导入所需的知识点',
      join_html: '正有所为，泰有未来\n建立多样化的人才引进渠道，网罗全球范围内的杰出人才',
      swi: '',
      swi1: '',
      swi2: '',
      topActive: 0,
      midActive: 0,
      secondActive: 0,
      productList: [],
      nindex: 0,
      example: [],
      newsList: [],
      banner_url: ''
    }
  },
  onload() {

  },
  created() {
    this.getApi()
  },
  computed: {
    swiper() {
      return this.$refs.mySwiperMid.$swiper;
    },
  },
  methods: {
    onSwiper(e) {
      this.swi = e
    },
    onSwiper1(e) {
      this.swi1 = e
    },
    onSwiper2(e) {
      this.swi2 = e
    },
    slideChange(e) {
      this.midActive = e.realIndex
      console.log(this.midActive);
    },
    onSlideChange(e) {
      this.topActive = e.realIndex
    },
    onSlideChange2(e) {
      this.secondActive = e.activeIndex;
      console.log(this.secondActive);
    },
    changeMid(e) {
      console.log(e);
      this.swi1.slideToLoop(e)
      this.midActive = e
    },
    prev() {
      this.swi.slidePrev();
    },
    next() {
      this.swi.slideNext()
    },
    prev1() {
      this.swi2.slidePrev();
    },
    next1() {
      this.swi2.slideNext()
    },
    chooseNewsTab(index) {
      this.nindex = index
    },
    getApi() {
      this.$api.apiGetBanner({}).then((res) => {
        if (res.code == 200) {
          this.banner = res.data
          console.log('banner',this.banner);
          if (this.language == 'zh') {
            this.banner_title = res.data.title
            this.banner_subtitle = res.data.subTitle
            this.banner_url = res.data.url
          } else {
            this.banner_title = res.data.titleEn
            this.banner_subtitle = res.data.subTitleEn
            this.banner_url = res.data.urlEn
          }
        } else {
          this.$message.error(res.msg);
        }

      })
      let simpleText = ref([])
      let mainText = ref([])
      this.$api.apiProductList({ 'isRecommended': 1 }).then(res => {
        if (res.code == 200) {

          this.productList = res.rows
          this.productList.map((item) => {
            if (item?.summary) {
              var arrstr = item?.summary.split('&quot;重要参数&quot;');
              item.simpleText = arrstr[0];
              mainText.value = arrstr[1];
              var arrstr1 = mainText.value.split('</span>')
              var arrstr2 = arrstr1[1].split('\n  <br /></p>')
              var arrstr3 = arrstr2[0].split('</p>\n<p>')
              arrstr3.pop()
              item.mainText = arrstr3
            }
            if (item?.summaryEn) {
              var arrstr = item?.summaryEn.split('&quot;重要参数&quot;');
              item.simpleTextEn = arrstr[0];
              if (arrstr[1]) {
                mainText.value = arrstr[1];
                var arrstr1En = mainText.value.split('</span>')
                var arrstr2En = arrstr1En[1].split('\n  <br /></p>')
                var arrstr3En = arrstr2En[0].split('</p>\n<p>')
                arrstr3En.pop()
                item.mainTextEn = arrstr3En
              }
            }
            item.articleAbstract = item?.articleAbstract?.split("\n")
            item.articleAbstractEn = item?.articleAbstractEn?.split("\n")
            console.log('productList', this.productList);

          })
        } else {
          this.$message.error(res.msg);
        }
      })
      this.$api.apiResultListRecomd({ 'isRecommended': 1 }).then(res => {
        if (res.code == 200) {

          this.swiperBanner = res.rows
          console.log('swip', this.swiperBanner);

        } else {
          this.$message.error(res.msg);
        }
      })
      // 
      this.$api.apiExampleCategoryList({ 'isRecommended': 1 }).then(res => {
        if (res.code == 200) {
          let list = res.rows;
          console.log(list);

          let newArray = [];
          let viod = 0;//数组初始量
          for (let i = 0; i <= list.length; i++) {
            if (i % 3 == 0 && i != 0) {
              newArray.push(list.slice(viod, i));
              viod = i;
            }
            if (i + 1 == list.length) {
              newArray.push(list.slice(viod, i + 1));
            }
          }
          this.example = newArray
          console.log('new', newArray);
        } else {
          this.$message.error(res.msg);
        }
      })
      // 新闻列表
      this.$api.apiNewsList({}).then(res => {
        if (res.code == 200) {
          res.rows.map((item, index) => {
            item.updateTime = moment(item.updateTime).format('YYYY-MM-DD')
          })
          this.newsList = res.rows

          console.log('新闻列表', this.newsList);
        } else {
          this.$message.error(res.msg);
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.swiper_link {
  width: 100%;
  height: calc(100vw / 1920 * 1080);
  position: relative;

  .swiper_sw {
    width: 100%;
    height: calc(100vw / 1920 * 1080);
  }

  .swiper_item {
    width: 100%;
    height: 100%;
  }

  .top_banner {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }

  .top_banner_info {
    width: 1760px;
    margin-left: auto;
    margin-right: auto;
  }

  .top_btn {
    margin-top: 140px;
    width: 140px;
    height: 54px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 10px;
    display: block;
  }
}

.swiper_link1 {
  width: 1500px;
  height: 650px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 120px;

  .swiper_item {
    width: 1500px;
    height: 650px;
  }

  .swiper_title {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
    color: #000;
  }

  .swiper_title::after {
    content: '';
    width: 70px;
    height: 2px;
    background: rgb(30, 80, 230);
    display: block;
    margin-top: 10px;
  }

  .swiper_goods_img {
    width: 305px;
    height: auto;
  }

  .item_box {
    width: 1000px;
    min-height: 500px;
    border-radius: 10px;
    background: #fff;
    padding: 80px 95px 55px 65px;
  }

  .item_text {
    font-size: 20px;
    color: #5a5a5a;
    margin-top: 5px;
  }

  .item_text::before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgb(30, 80, 230);
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }


  .icon_job {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -120px;
    margin: auto;
    cursor: pointer;
    width: 60px;
  }

  .job_r {
    left: auto;
    right: -120px;
  }
}

.swiper_result {
  width: 100%;
  height: 650px;
  margin-top: 80px;

  &_item {
    width: 1300px;
    height: 650px;
  }

  .result_item {
    width: 1300px;
    height: 650px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding-top: 80px;
    padding-left: 95px;
    padding-right: 95px;
    font-size: 36px;
    line-height: 110px;
    color: #fff;
    font-weight: bold;
    display: block;
    position: relative;
  }
}

.diy_pagination_nav {
  width: 1760px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;

  .sq {
    background: rgb(210, 210, 210);
    width: 1760px;
    height: 2px;
  }

  .sq_item {
    position: relative;
    font-size: 24px;
    color: #5a5a5a;
    text-align: center;
    padding-top: 20px;

    &.active {
      color: rgb(30, 80, 230);
    }

    &.active::before {
      content: '';
      width: 180px;
      height: 5px;
      position: absolute;
      top: -2.5px;
      left: 50%;
      transform: translateX(-50%);
      background: rgb(30, 80, 230);
    }
  }
}

.sq_item+.sq_item {
  margin-left: 120px;
}

.swiper_link2 {
  height: 800px;
  width: 100%;
  padding-left: 80px;
  position: relative;
  margin-top: 50px;

  .icon_job {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 80px;
    margin: auto;
    cursor: pointer;
    width: 60px;
    z-index: 10;
  }

  .job_r {
    left: auto;
    right: 80px;
  }

  .swiper_link2_swiper {
    height: 800px;
    width: 100%;
    position: relative;
    opacity: 1;
  }

  .swiper_item {
    height: 800px;
    width: 1400px;
    position: relative;
    opacity: 0.5;
  }

  .swiper_item.active {
    opacity: 1;
  }

  .first_item {
    width: 870px;

    &_img {
      width: 870px;
      height: 500px;
      object-fit: cover;
      border-radius: 10px;

    }

    .bt1 {
      margin-top: 50px;
      margin-left: 1px;
      width: 140px;
      height: 50px;
      line-height: 46px;
      text-align: center;
      border-radius: 10px;
      position: relative;
      background-color: #fff;
      background-clip: padding-box;
      color: rgb(30, 80, 230);
      font-size: 16px;
      display: block;
      /*important*/
      --border-width: 3px;
      --border-radius: 10px;
      --border-color: linear-gradient(-90deg, rgba(80, 200, 255, 1), rgba(30, 80, 230, 1));
      background: transparent;
    }

    .bt1::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: var(--border-width);
      border-radius: var(--border-radius);
      background: var(--border-color);
      --mask-bg: linear-gradient(red, red);
      --mask-clip: content-box, padding-box;
      -webkit-mask-image: var(--mask-bg), var(--mask-bg);
      -webkit-mask-clip: var(--mask-clip);
      -webkit-mask-composite: destination-out;
    }
    .bt1:hover{background: #1e50e6;color: #fff;border-color: #1e50e6 !important;}
    .bt1:hover::after{display: none;}
  }

  .second_item+.second_item {
    margin-top: 35px;
  }

  .second_item {
    width: 490px;
display: block;
    &_img {
      width: 490px;
      height: 250px;
      border-radius: 10px;
      object-fit: contain;
    }
  }
}

.tab_nav {
  .tab_item {
    width: 118px;
    height: 17px;
    text-align: center;
    font-size: 15px;
    line-height: 17px;
    color: #5a5a5a;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
  }

  .tab_item.active {
    color: rgb(30, 80, 230)
  }

  .tab_item+.tab_item {
    border-left: 1px solid #5a5a5a;
  }
}

.news_btn {
  width: 140px;
  height: 50px;
  line-height: 46px;
  text-align: center;
  color: rgb(30, 80, 230);
  font-size: 16px;
  cursor: pointer;
  border: 0.0104167rem solid transparent;
  border-radius: 10px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, rgb(246, 246, 246), rgb(246, 246, 246)), linear-gradient(-90deg, rgb(80, 200, 255), rgb(30, 80, 230));
}
.news_btn:hover{background: #1e50e6;color: #fff;}
.p3_5{padding: 30px 50px;}
.news_item_big {
  width: 785px;
  height: 525px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  

  .news_btn_white {
    min-width: 80px;
    height: 30px;
    border-radius: 5px;
    line-height: 26px;
    text-align: center;
    border: 0.0104167rem solid #fff;
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
    display: inline-block;
  }

}

.news_item {
  width: 950px;
  height: 165px;
  background: #fff;
  border-radius: 10px;

  .cover {
    width: 305px;
    height: 165px;
    border-radius: 10px 0 0 10px;
    object-fit: cover;
  }

  .news_btn_mini {
    min-width: 80px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #1e50e6;
    position: relative;
    text-align: center;
    border-radius: 10px;
    --border-width: 3px;
    --border-radius: 10px;
    --border-color: linear-gradient(-90deg, rgba(80, 200, 255, 1), rgba(30, 80, 230, 1));
    background: transparent;
    padding: 0 10px;
  }

  .news_btn_mini::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: var(--border-width);
    border-radius: var(--border-radius);
    background: var(--border-color);
    --mask-bg: linear-gradient(red, red);
    --mask-clip: content-box, padding-box;
    -webkit-mask-image: var(--mask-bg), var(--mask-bg);
    -webkit-mask-clip: var(--mask-clip);
    -webkit-mask-composite: destination-out;
  }
  .news_btn_mini:hover{background: #1e50e6;color: #fff;border-color: #1e50e6 !important;}
    .news_btn_mini:hover::after{display: none;}
  .tt {
    color: #000;
  }

  &+& {
    margin-top: 15px;
  }

  &:hover .tt {
    color: #1e50e6
  }
}

.router_link {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 750px;

  .study_btn {
    width: 140px;
    height: 50px;
    text-align: center;
    font-size: 16px;
    color: #1e50e6;
    line-height: 48px;
    position: relative;
    border-radius: 10px;
    --border-width: 3px;
    --border-radius: 10px;
    --border-color: linear-gradient(-90deg, rgba(80, 200, 255, 1), rgba(30, 80, 230, 1));
    background: transparent;
    cursor: pointer;
  }

  .study_btn::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: var(--border-width);
    border-radius: var(--border-radius);
    background: var(--border-color);
    --mask-bg: linear-gradient(red, red);
    --mask-clip: content-box, padding-box;
    -webkit-mask-image: var(--mask-bg), var(--mask-bg);
    -webkit-mask-clip: var(--mask-clip);
    -webkit-mask-composite: destination-out;
  }
}
</style>
<style>
.swiper_mid .swiper-pagination {
  height: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  bottom: 0;
}


.swiper_mid .swiper-pagination .swiper-pagination-bullet {
  width: 32px;
  height: 4px;
  background: #fff;
  display: block;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 0;
  opacity: 1;
}

.swiper_mid .swiper-pagination .swiper-pagination-bullet-active {
  display: block;
  background: rgb(30, 80, 230);
  width: 32px;
  height: 4px;
}
</style>
